import React, { Component } from "react"
import PropTypes from "prop-types"

class CountdownTimer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
    }
  }

  componentDidMount() {
    // update every second
    this.interval = setInterval(() => {
      const date = this.calculateCountdownTimer(this.props.date)
      date ? this.setState(date) : this.stop()
    }, 1000)
  }

  componentWillUnmount() {
    this.stop()
  }

  calculateCountdownTimer(endDate) {
    // set time you want countdown to end
    const targetDate = new Date(this.props.date).getTime()

    const timeLeft = {
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
    }

    // get amount of second between now and target date
    const currentDate = new Date().getTime()
    let secondsLeft = (targetDate - currentDate) / 1000
    // determine amount of days, hours, minutes, seconds remaining
    // as a side note parseInt parses a string and brings it back as an integer
    timeLeft.days = parseInt(secondsLeft / 86400)
    secondsLeft = secondsLeft % 86400

    timeLeft.hours = parseInt(secondsLeft / 3600)
    secondsLeft = secondsLeft % 3600

    timeLeft.minutes = parseInt(secondsLeft / 60)
    timeLeft.seconds = parseInt(secondsLeft % 60)

    return timeLeft
  }

  stop() {
    clearInterval(this.interval)
  }

  render() {
    const countDown = this.state

    return (
      <div className="time">
        {countDown.days}d, {countDown.hours}h, {countDown.minutes}m,{" "}
        {countDown.seconds}s
      </div>
    )
  }
}

CountdownTimer.propTypes = {
  date: PropTypes.string.isRequired,
}

CountdownTimer.defaultProps = {
  date: new Date(),
}

export default CountdownTimer
