import React from "react"
import RazRocket from "../images/razroo-rocket.svg"
import Layout from "../components/layout"
import Menu from "../components/global/Menu"
import Footer from "../components/global/Footer"
import CountdownTimer from "../components/countdown-timer"
import SEO from "../components/seo"

const ProductPage = () => (
  <Layout bodyTheme="theme-product">
    <SEO
      title="Razroo Product Launch Date"
      keywords={[`razroo`, `product`, `page`, `rocket`]}
    />
    <header>
      <Menu />
    </header>
    <main className="page-wrapper">
      <div id="stars-group-1" />
      <div id="stars-group-2" />
      <div id="stars-group-3" />
      <div id="stars-group-4" />
      <div id="stars-group-5" />
      <div id="stars-group-6" />
      <section className="section section-first">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Razroo Product Demo Video</h1>
              <div class="Product__VideoWrapper">
                <iframe
                  width="560"
                  height="349"
                  src="https://www.youtube.com/embed/xmaJD2v5bYM"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="launch-meta">
                <div className="label">Reach for the stars! #BeMore</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </main>
  </Layout>
)

export default ProductPage
